@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(https://fonts.googleapis.com/css?family=Black+Han+Sans&display=swap);
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

* {
  font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", sans-serif;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Black Han Sans", "Spoqa Han Sans", "Spoqa Han Sans JP",
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

/* modal */
.modal .modal-content {
  background-color: #202020;
  width: 792px;
  border-radius: 0;
  border: none;
  flex: none;
  padding: 0;
  position: relative;
}

.modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-width: 100%;
  margin: 0;
  flex-direction: column;
}

@media (max-width: 768px) {
  .modal .modal-content {
    width: 100%;
    height: 100vh;
  }
}

